/**
 * We're not importing @ecomm/copy types here because they do not match the CDA's response
 * TODO: tests; types; handling DE fallbacks
 */

const extractCtaWithUrlFields = (ctaWithUrlData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, text, link, targetBlank, ariaLabel, image },
  } = ctaWithUrlData;
  return {
    key,
    text,
    targetBlank,
    ariaLabel,
    url: link?.fields?.url,
    image: image ? extractMediaFields(image) : undefined,
    __typename: id,
  };
};

const extractCtaWithDriftInteractionFields = (ctaWithDriftInteractionData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, text, interactionId },
  } = ctaWithDriftInteractionData;
  return {
    key,
    text,
    interactionId,
    __typename: id,
  };
};

const extractHeaderBodyImageFields = (headerBodyImageData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, header, body, image },
  } = headerBodyImageData;

  return {
    key,
    header,
    body,
    image: extractImageFields(image),
    __typename: id,
  };
};

export const extractLongHeaderBodyFootnoteFields = ({
  sys: {
    contentType: {
      sys: { id },
    },
  },
  fields: { key, header, body, footnote, tooltip },
}: any) => {
  return {
    key,
    header,
    body,
    footnote,
    tooltip: tooltip ? extractKeyValueFields(tooltip).value : undefined,
    __typename: id,
  };
};

const extractLeadGenFields = (leadGenSetData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: {
      key,
      campaignId,
      campaignName,
      theme,
      headlineText,
      supportText,
      ctaText,
      placeholder,
      emailCaptureSuccess,
      emailCaptureFailure,
    },
  } = leadGenSetData;
  return {
    key,
    campaignId,
    campaignName,
    theme,
    headlineText,
    supportText,
    ctaText,
    placeholder,
    emailCaptureSuccess,
    emailCaptureFailure,
    __typename: id,
  };
};

const extractLegalTextFields = (legalTextSetData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, text },
  } = legalTextSetData;
  return {
    key,
    text,
    __typename: id,
  };
};

const ctaWithUrlSetMap = {
  ctaWithUrl: extractCtaWithUrlFields,
  ctaWithDriftInteraction: extractCtaWithDriftInteractionFields,
};

const extractCtaWithUrlSetFields = (ctaWithUrlSetData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, title, ctas },
  } = ctaWithUrlSetData;
  const formattedCtas = ctas?.map((ctaEntry: any) => {
    const ctaType = ctaEntry.sys.contentType.sys.id;
    return ctaWithUrlSetMap[ctaType] ? ctaWithUrlSetMap[ctaType](ctaEntry) : ctaEntry;
  });
  return {
    key,
    title,
    ctas: formattedCtas,
    __typename: id,
  };
};

const extractImageFields = (imageData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, mobile, tablet, desktop, caption },
  } = imageData;
  return {
    key,
    mobile: mobile?.fields,
    tablet: tablet?.fields,
    desktop: desktop?.fields,
    caption,
    __typename: id,
  };
};

const extractMediaFields = (mediaData: any) => {
  const {
    sys: { id },
    fields: { title, description, file },
  } = mediaData;

  return {
    title,
    description,
    file,
    url: file?.url,
    __typename: id,
  };
};

const extractKeyValueFields = (keyValueData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, value },
  } = keyValueData;
  return {
    key,
    value,
    __typename: id,
  };
};

const extractJsonKeyValueFields = (jsonKeyValue: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, value },
  } = jsonKeyValue;
  return {
    key,
    value,
    __typename: id,
  };
};

const extractPromotionalTextFields = (promotionalTextData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, text },
  } = promotionalTextData;

  return {
    key,
    text,
    __typename: id,
  };
};

const extractMarkdownFields = (markdownPageData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, body, overflow },
  } = markdownPageData;

  return {
    key,
    body,
    overflow,
    __typename: id,
  };
};

const extractProductDetailsCardFields = (productDetailsCardData: any) => {
  return productDetailsCardData?.map((productDetailsCard: any) => {
    const {
      sys: {
        contentType: {
          sys: { id },
        },
      },
      fields: {
        key,
        image,
        productName,
        description,
        device,
        featureLists,
        primaryCta,
        secondaryCta,
        ribbonText,
        promoBadgeText,
      },
    } = productDetailsCard;

    return {
      key,
      image: image && extractMediaFields(image),
      productName,
      description,
      device,
      featureLists: featureLists && extractMarkdownFields(featureLists),
      primaryCta: primaryCta && extractCtaWithUrlFields(primaryCta),
      secondaryCta: secondaryCta && extractCtaWithUrlFields(secondaryCta),
      ribbonText,
      promoBadgeText,
      __typename: id,
    };
  });
};

export const extractProductNavFields = (productNavData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { key, title, products, secondaryLinks, promoBadgeText },
  } = productNavData;

  return {
    key,
    title,
    products: extractProductDetailsCardFields(products),
    secondaryLinks: secondaryLinks && extractCtaWithUrlSetFields(secondaryLinks),
    __typename: id,
    promoBadgeText,
  };
};

const getSysId = (data: any) => {
  return {
    spaceId: data?.sys?.space?.sys?.id || null,
    entryId: data?.sys?.id || null,
  };
};

export const extractAggregateFields = (aggregateData: any): any => {
  const { key, aggregate: aggregateEntries } = aggregateData.fields as any;

  let aggregateFields;

  try {
    aggregateFields = {
      [key]: aggregateEntries?.reduce((acc: any, item: any) => {
        const contentType = item.sys.contentType.sys.id;
        const contentKey = item.fields.key;

        return {
          ...acc,
          [contentKey]: contentAggregateTypeMap[contentType]
            ? contentType === 'contentAggregate'
              ? contentAggregateTypeMap[contentType](item)[contentKey]
              : contentAggregateTypeMap[contentType](item)
            : item,
        };
      }, {}),
    };
  } catch (err) {
    const { spaceId, entryId } = getSysId(aggregateData);
    const { aggregate } = aggregateData?.fields ?? [];
    aggregate.forEach((entry: any) => {
      if (!entry?.fields) {
        console.error(`Failing on entry: ${entry?.sys?.id}`);
      }
    });
    if (spaceId && entryId) {
      console.error(
        `Parent Contentful Entry: https://app.contentful.com/spaces/${spaceId}/entries/${entryId}`,
      );
    }
    console.error(
      `Key Name: ${key} or descendant does not match up with the provided mapToView extractor`,
    );
    throw new Error(err);
  }

  return aggregateFields;
};

const toMapper = (e: any) => {
  switch (e.sys.contentType.sys.id) {
    case 'navigation':
      return extractNavigationFields(e);
    case 'productNav':
      return extractProductNavFields(e);
    case 'ctaWithUrlSet':
      return extractCtaWithUrlSetFields(e);
    default:
      return e;
  }
};

export const extractNavigationFields = (navigationData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: {
      key,
      subnavs,
      geoMenu,
      userMenu,
      panelNavLinks,
      ariaLabel,
      logoAriaLabel,
      hamburgerButtonLabel,
      cartButtonLabel,
      title,
      toggleLocator,
      toggleAccount,
      toggleCart,
    },
  } = navigationData;

  return {
    key,
    subnavs: subnavs?.map(toMapper),
    geoMenu: geoMenu && extractAggregateFields(geoMenu),
    userMenu: userMenu && extractAggregateFields(userMenu),
    panelNavLinks: panelNavLinks && extractCtaWithUrlSetFields(panelNavLinks),
    ariaLabel: ariaLabel && extractKeyValueFields(ariaLabel),
    logoAriaLabel: logoAriaLabel && extractKeyValueFields(logoAriaLabel),
    hamburgerButtonLabel:
      hamburgerButtonLabel && extractKeyValueFields(hamburgerButtonLabel),
    cartButtonLabel: cartButtonLabel && extractKeyValueFields(cartButtonLabel),
    toggleLocator,
    toggleAccount,
    toggleCart,
    title,
    __typename: id,
  };
};

export const extractProductRecommendationsCohortFields = (cohortData: any) => {
  const {
    fields: { key: cohortKeyName, headline, subHeadline, position, products },
  } = cohortData;

  return {
    cohortKeyName,
    headline,
    subHeadline,
    position,
    products: products?.map((productData: any) => {
      const {
        fields: {
          productSlug: slug,
          factoid,
          pill,
          factoidIcon,
          pillTheme,
          priceDisplay,
          factoidEyebrow,
        },
      } = productData;
      return {
        slug,
        factoid,
        pill,
        factoidIcon,
        pillTheme,
        priceDisplay,
        factoidEyebrow,
      };
    }),
    marketingTiles: [],
  };
};

export const extractVideoFields = (videoData: any) => {
  const {
    sys: {
      contentType: {
        sys: { id },
      },
    },
    fields: { poster, video, tracks },
  } = videoData;

  return {
    poster: poster && extractAssetFields(poster),
    video: video && extractAssetFields(video),
    tracks:
      tracks &&
      tracks?.map(
        (track: any) => track?.fields?.src && extractAssetFields(track.fields.src),
      ),
    __typename: id,
  };
};

export const extractAssetFields = (assetData: any) => {
  const {
    fields: {
      title,
      file: { url },
    },
  } = assetData;

  return {
    title,
    url,
  };
};

const contentAggregateTypeMap = {
  ...ctaWithUrlSetMap,
  keyValue: extractKeyValueFields,
  jsonKeyValue: extractJsonKeyValueFields,
  ctaWithUrlSet: extractCtaWithUrlSetFields,
  componentLeadGen: extractLeadGenFields,
  headerBodyImage: extractHeaderBodyImageFields,
  legalText: extractLegalTextFields,
  image: extractImageFields,
  promotionalText: extractPromotionalTextFields,
  video: extractVideoFields,
  contentAggregate: extractAggregateFields,
  navigation: extractNavigationFields,
  productRecommendationsCohort: extractProductRecommendationsCohortFields,
  longHeaderBodyFootnote: extractLongHeaderBodyFootnoteFields,
};
